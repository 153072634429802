<template xmlns:v="http://www.w3.org/1999/xhtml">
  <div style="width: 100%" class="outerBox" id="mianscroll">
    <WebHeader></WebHeader>
    <Carousel></Carousel>
    <div style="width: 100%;display: flex;flex-direction: column;align-items: center">
      <div style="width: 1280px;">
        <div style="height: 950px;display: flex;flex-direction: column;align-items: center;justify-content: center">
          <div style="width: 1067px;display: flex;justify-content: space-between;align-items: center">
            <img src="../assets/images/home/idea.png" >
            <div style="margin-left: 60px">
              <div style="font-size: 32px;color: #173F35">麦祥理念</div>
              <div style="background: #173F35;height: 4px;width: 70px;margin: 20px 0"></div>
              <div style="font-size: 24px;">“成功的分享不仅是产品的传递，更是梦想的播种。每一次分享，都是在为他人开启一扇通往美好可能的门”</div>
            </div>
          </div>
          <img src="../assets/images/home/bigTeaImg.png" style="width: 1067px;height: 421px">
        </div>
        <div>
          <div style="display: grid;grid-row-gap: 50px;grid-column-gap: 10px;grid-template-columns: repeat(3,auto);">
            <div @click="goToGoods(item)" style="display: flex;flex-direction: column;align-items: center;cursor: pointer" v-for="item in newsTypeList">
              <img :src="item.img.url" style="width: 412px;height: 300px">
              <div style="font-size: 20px;font-weight:bold;margin: 30px 0 20px 0">{{item.name}}</div>
              <div style="background: #2C6B62;height: 2px;width: 128px"></div>
            </div>
          </div>
          <div style="width: 100%;display: flex;flex-direction: row-reverse;margin: 50px 0;cursor: pointer" @click="goTo('/goods')">
            <div style="width: 100px;height: 35px;border: #B9C5C2 solid 1px;color: #173F35;font-size: 14px;display: flex;justify-content: center;align-items: center">more</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bigImg"></div>
    <div style="width: 100%;display: flex;flex-direction: column;align-items: center;background: #F9F9F9">
      <div style="width: 1280px;height: 1340px">
        <NewsCarousel></NewsCarousel>
        <div style="display: flex;justify-content: space-between;height: 400px;width: 1280px;margin-top: 40px">
          <div style="width: 400px;height: 400px;background: white;cursor: pointer" v-for="item,index in hotList" @click="detailEvent(item)">
            <img :src="item.img.url" style="width: 400px;height: 240px">
            <div style="width: 400px;height: 160px;padding: 18px 26px 30px 18px">
              <div style="font-size: 19px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">{{item.title}}</div>
              <div style="font-size: 14px;color: #636364;display: flex;align-items: center;margin-top: 10px">
                <img src="../assets/images/home/time.png" style="width: 14px;height: 14px;margin-right: 5px">
                <div>{{ item.updateTime }}</div>
              </div>
              <div style="font-size: 15px;color: #636364;display: flex;align-items: center;margin-top: 10px">
                {{item.describe}}
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex;justify-content: space-between;height: 320px;width: 1280px;margin-top: 40px">
          <div style="width: 400px;height: 320px"><img src="../assets/images/home/img1.png"></div>
          <div style="width: 400px;height: 320px"><img src="../assets/images/home/img2.png"></div>
          <div style="width: 400px;height: 320px"><img src="../assets/images/home/img3.png"></div>
        </div>
      </div>
    </div>
    <LevitationBar ref="LevitationBar"></LevitationBar>
    <WebFooter></WebFooter>
  </div>

</template>

<script>
import WebHeader from "@/views/components/WebHeader.vue";
import Carousel from "@/views/components/Carousel.vue";
import NewsCarousel from "@/views/components/NewsCarousel.vue";
import WebFooter from "@/views/components/WebFooter.vue";
import LevitationBar from "@/views/components/LevitationBar.vue";
import MenuUtils from "@/menuUtils";
export default {
name: "Web",
  components:{
    WebHeader,
    Carousel,
    NewsCarousel,
    WebFooter,
    LevitationBar
  },
  data(){
    return {
      hotList:[],
      bannerList:[],
      newsTypeList:[],
    }
  },
  methods:{
    detailEvent(item){
      MenuUtils.routerQueryTo("/newsDetail",{id:item.id},true)
    },
    goTo(url){
      MenuUtils.routerQueryTo(url);
    },
    goToGoods(item){
      MenuUtils.routerQueryTo("/goods",{categoryId:item.id},true)
    }
  },
  mounted(){
    this.$Http('/categoryList', {  }, 'GET').then(result => {
      let array=[];
      for(let i=0;i<result.data.data.length;i++){
        let item=result.data.data[i];
        if(!item.disabled){
          array.push(item);
        }
      }
      this.newsTypeList = array;
    });
    this.$Http("newsHotList",{},"POST").then(result=>{
      this.hotList=result.data.data;
    })
  }
}
</script>

<style scoped>
  .outerBox {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
  }
  .bigImg{
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 680px;
    background-image: url("../assets/images/home/bigImg.png");
  }
</style>