<template>
    <el-carousel  arrow="always" height="700px">
        <el-carousel-item v-for="item in bannerList" :key="item">
            <img :src="getImageUrl(item)" style="width: 100%;height: 700px;object-fit: cover">
        </el-carousel-item>
    </el-carousel>
</template>

<script>
    export default {
        name: "Carousel",
        data(){
            return {
                bannerList:["banner1.png",
                            "banner2.png",
                            "banner3.png",
                            "banner4.png"]
            }
        },
        methods: {
            getImageUrl(imageName) {
                return require(`@/assets/images/home/${imageName}`);
            }
        }
    }
</script>

<style scoped>
    .el-carousel >>> .el-carousel__arrow {
        width: 60px !important;
        height: 60px !important;
    }
    .el-carousel >>> .el-carousel__arrow--left {
        left: 55px !important;
        background-image: url("../../assets/images/home/left.png");
        color: transparent;
    }
    .el-carousel >>> .el-carousel__arrow--right {
        right: 55px !important;
        background-image: url("../../assets/images/home/right.png");
        color: transparent;
    }

    /deep/.el-carousel__indicators--horizontal {
        position: absolute;
        bottom: 10px;
        text-align: right;
    }
    /deep/.el-carousel__indicator--horizontal button {
        width: 80px;
        height: 5px;
        background: #ffffff;
        border-radius: 2px;
        opacity: 0.5;
    }


</style>