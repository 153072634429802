import Index from './views/Index.vue'
import Login from './views/Login.vue'
import Goods from './views/Goods.vue'
import Home from './views/Home.vue'
import Web from './views/Web.vue'
import News from './views/News.vue'

/**
 * 参数：auth 表示相应页面是否需要判断登录
 * 参数：keepAlive 标识相应页面是否缓存
 */

let routes = [
    {path: '/web', component: Web, meta: {auth: false}},
    {path: '/news', component: News, meta: {auth: false}},
    {path: '/newsDetail', component: () => import('@/views/NewsDetail.vue'), meta: {auth: false}},
    {path: '/about', component: () => import('@/views/About.vue'), meta: {auth: false}},
    {path: '/business', component: () => import('@/views/Business.vue'), meta: {auth: false}},
    {path: '/goods', component: Goods, meta: {auth: false}},
    {path: '/goodsDetail', component: () => import('@/views/GoodsDetail.vue'), meta: {auth: false}},

    {path: '/login', component: Login, meta: {auth: false}},
    //重定向
    {path: '/', redirect: '/web', meta: {auth: false}},
    {path: '/index', redirect: '/index/home', meta: {auth: true}},
    {path: '/home', redirect: '/index/home', meta: {auth: true}},
    //赖加载
    {
        path: '/index',
        component: Index,
        meta: {
            auth: true,
        },
        children: [
            //首页直接加载
            {
                path: 'banner',
                component: () => import('@/views/Banner.vue'),
                meta: {auth: true, breadcrumb: [{name: '商城管理', route: ''}, {name: '轮播管理', route: ''}]}
            },
            {
                path: 'feedback',
                component: () => import('@/views/CustomerFeedback.vue'),
                meta: {auth: true, breadcrumb: [{name: '商城管理', route: ''}, {name: '用户反馈', route: ''}]}
            },
            {
                path: 'user',
                component: () => import('@/views/User.vue'),
                meta: {auth: true, breadcrumb: [{name: '系统管理', route: ''}, {name: '用户管理', route: ''}]}
            },
            {
                path: 'home-app',
                component: () => import('@/views/HomeApp.vue'),
                meta: {auth: true, breadcrumb: [{name: '商城管理', route: ''}, {name: '商城首页', route: ''}]}
            },

            {path: 'home', component: Home, meta: {auth: true, breadcrumb: [{name: '首页', route: ''}]}},
            {
                path: 'order-list', name: 'order-list', component: () => import('./views/page/OrderList.vue'), meta: {
                    auth: true, keepAlive: true,
                    breadcrumb: [
                        {name: '业务管理', route: ''},
                        {name: '订单管理', route: ''}
                    ]
                }
            },
            {
                path: 'study', name: 'study', component: () => import('./views/page/Study.vue'), meta: {
                    auth: true, keepAlive: true,
                    breadcrumb: [
                        {name: '学习中心', route: ''},
                        {name: '学习管理', route: ''}
                    ]
                }
            },
            {
                path: 'studyEditor',
                name: 'studyEditor',
                component: () => import('./views/page/StudyEditor.vue'),
                meta: {
                    auth: true, keepAlive: false,
                    breadcrumb: [
                        {name: '学习中心', route: ''},
                        {name: '学习管理', route: ''},
                        {name: '信息编辑', route: ''}
                    ]
                }
            },


            {
                path: 'study-category-list',
                name: 'study-category-list',
                component: () => import('./views/page/StudyCategoryList.vue'),
                meta: {
                    auth: true, keepAlive: true,
                    breadcrumb: [
                        {name: '学习中心', route: ''},
                        {name: '学习类目', route: ''}
                    ]
                }
            },

            {
                path: 'manual', name: 'manual', component: () => import('./views/page/Manual.vue'), meta: {
                    auth: true, keepAlive: true,
                    breadcrumb: [
                        {name: '学习中心', route: ''},
                        {name: '操作手册', route: ''}
                    ]
                }
            },
            {
                path: 'manualEditor',
                name: 'manualEditor',
                component: () => import('./views/page/ManualEditor.vue'),
                meta: {
                    auth: true, keepAlive: false,
                    breadcrumb: [
                        {name: '学习中心', route: ''},
                        {name: '操作手册', route: ''},
                        {name: '信息编辑', route: ''}
                    ]
                }
            },


            {
                path: 'manual-category-list',
                name: 'manual-category-list',
                component: () => import('./views/page/ManualCategoryList.vue'),
                meta: {
                    auth: true, keepAlive: true,
                    breadcrumb: [
                        {name: '学习中心', route: ''},
                        {name: '操作手册类目', route: ''}
                    ]
                }
            },
            {
                path: 'order-detail',
                name: 'order-detail',
                component: () => import('./views/page/OrderDetail.vue'),
                meta: {
                    auth: true, keepAlive: false,
                    breadcrumb: [
                        {name: '业务管理', route: ''},
                        {name: '订单管理', route: '/index/order-list'},
                        {name: '订单详情', route: ''}
                    ]
                }
            },
            {
                path: 'service-list',
                name: 'service-list',
                component: () => import('./views/page/ServiceList.vue'),
                meta: {
                    auth: true, keepAlive: true,
                    breadcrumb: [
                        {name: '业务管理', route: ''},
                        {name: '服务管理', route: ''}
                    ]
                }
            },
            {
                path: 'goods-list', name: 'GoodsList', component: () => import('./views/page/GoodsList.vue'), meta: {
                    auth: true, keepAlive: true,
                    breadcrumb: [
                        {name: '业务管理', route: ''},
                        {name: '商品管理', route: ''}
                    ]
                }
            },
            {
                path: 'goods-editor',
                name: 'GoodsEditor',
                component: () => import('./views/page/GoodsEditor.vue'),
                meta: {
                    auth: true, keepAlive: false,
                    breadcrumb: [
                        {name: '业务管理', route: ''},
                        {name: '商品管理', route: ''}
                    ]
                }
            },
            {
                path: 'goods-detail',
                name: 'goods-detail',
                component: () => import('./views/page/GoodsDetail.vue'),
                meta: {
                    auth: true, keepAlive: false,
                    breadcrumb: [
                        {name: '业务管理', route: ''},
                        {name: '商品管理', route: '/index/goods-list'},
                        {name: '商品详情', route: ''}
                    ]
                }
            },
            {
                path: 'category-list',
                name: 'category-list',
                component: () => import('./views/page/CategoryList.vue'),
                meta: {
                    auth: true, keepAlive: true,
                    breadcrumb: [
                        {name: '业务管理', route: ''},
                        {name: '类目管理', route: ''}
                    ]
                }
            },
            {
                path: 'shop', name: 'shop', component: () => import('./views/page/Shop.vue'), meta: {
                    auth: true, keepAlive: false,
                    breadcrumb: [
                        {name: '业务管理', route: ''},
                        {name: '新品介绍', route: ''}
                    ]
                }
            },
            {
                path: 'goods-new', name: 'GoodsNew', component: () => import('./views/page/GoodsNew.vue'), meta: {
                    auth: true, keepAlive: false,
                    breadcrumb: [
                        {name: '业务管理', route: ''},
                        {name: '新品介绍', route: ''}
                    ]
                }
            },
            {
                path: 'data',
                component: () => import('@/views/page/Data.vue'),
                meta: {auth: true, breadcrumb: [{name: '业务管理', route: ''}, {name: '分佣管理', route: ''}]}
            },
            {
                path: 'personnel',
                name: 'Personnel',
                component: () => import('@/views/page/Personnel.vue'),
                meta: {auth: true, keepAlive: true, breadcrumb: [{name: '业务管理', route: ''}, {name: '客户管理', route: ''}]}
            },
            {
                path: 'personnelDetail',
                name: 'PersonnelDetail',
                component: () => import('@/views/page/PersonnelDetail.vue'),
                meta: {
                    auth: true,
                    keepAlive: false,
                    breadcrumb: [{name: '业务管理', route: ''}, {name: '客户管理', route: ''}]
                }
            },
            {
                path: 'money',
                name: 'Money',
                component: () => import('@/views/page/Money.vue'),
                meta: {
                    auth: true,
                    keepAlive: false,
                    breadcrumb: [{name: '业务管理', route: ''}, {name: '分成管理', route: ''}]
                }
            },
            {
                path: 'moneyGet',
                name: 'MoneyGet',
                component: () => import('@/views/page/MoneyGet.vue'),
                meta: {auth: true, keepAlive: true, breadcrumb: [{name: '业务管理', route: ''}, {name: '提现管理', route: ''}]}
            },
            {
                path: 'moneyDetail',
                name: 'MoneyDetail',
                component: () => import('@/views/page/MoneyDetail.vue'),
                meta: {auth: true, keepAlive: true, breadcrumb: [{name: '业务管理', route: ''}, {name: '提现详情', route: ''}]}
            },
            {
                path: 'supplier',
                name: 'Supplier',
                component: () => import('@/views/page/Supplier.vue'),
                meta: {
                    auth: true,
                    keepAlive: false,
                    breadcrumb: [{name: '业务管理', route: ''}, {name: '供应商管理', route: ''}]
                }
            },

            {
                path: 'news',
                name: 'News',
                component: () => import('@/views/page/News.vue'),
                meta: {auth: true, keepAlive: true, breadcrumb: [{name: '网站管理', route: ''}, {name: '资讯管理', route: ''}]}
            },
            {
                path: 'newsEditor',
                component: () => import('@/views/page/NewsEditor.vue'),
                meta: {
                    auth: true,
                    keepAlive: false,
                    breadcrumb: [{name: '网站管理', route: ''}, {name: '资讯管理', route: ''}]
                }
            },
            {
                path: 'newsType',
                name: 'NewsType',
                component: () => import('@/views/page/NewsType.vue'),
                meta: {
                    auth: true,
                    keepAlive: false,
                    breadcrumb: [
                        {name: '业务管理', route: ''},
                        {name: '资讯类型', route: ''}
                    ]
                }
            },
            {
                path: 'webAbout',
                name: 'WebAbout',
                component: () => import('@/views/page/WebAbout.vue'),
                meta: {
                    auth: true,
                    keepAlive: false,
                    breadcrumb: [
                        {name: '网站管理', route: ''}
                    ]
                }
            },
            {
                path: 'webBusiness',
                name: 'WebBusiness',
                component: () => import('@/views/page/WebBusiness.vue'),
                meta: {
                    auth: true,
                    keepAlive: false,
                    breadcrumb: [
                        {name: '网站管理', route: ''}
                    ]
                }
            },
        ],
    }
];
export default routes;