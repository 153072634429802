/**
 * 全局异常处理
 */

import {http} from "@/http";

let prePageError;
export function errorHandler(err, vm, info){
    console.log(info)
    // let data={page:vm.$el.baseURI, component:vm.$options.name||'无', err:err.message};
    // if(prePageError!=err.message){
    //     // http("错误日志",{data:data.toString()},"POST",false)
    //     prePageError=err.message;
    // }
}
