const menu=[
  {id:1,name:'首页',icon:'fa fa-television',parentId:'',route:''},
  {id:11,name:'首页',icon:'fa fa-television',parentId:'1',route:'/index/home'},
  {id:2,name:'业务管理',icon:'fa fa-database',parentId:'',route:''},
  {id:21,name:'订单管理',icon:'',parentId:'2',route:'/index/order-list'},
  {id:22,name:'商品管理',icon:'',parentId:'2',route:'/index/goods-list'},
  {id:23,name:'类目管理',icon:'',parentId:'2',route:'/index/category-list'},
  {id:26,name:'客户管理',icon:'',parentId:'2',route:'/index/personnel'},
  {id:27,name:'供应商管理',icon:'',parentId:'2',route:'/index/supplier'},
  {id:28,name:'分成管理',icon:'',parentId:'2',route:'/index/money'},
  {id:29,name:'提现管理',icon:'',parentId:'2',route:'/index/moneyGet'},
  {id:25,name:'佣金配置',icon:'',parentId:'2',route:'/index/data'},

  {id:4,name:'商城管理',icon:'fa  fa-gears',parentId:'',route:''},
  {id:41,name:'商城首页',icon:'',parentId:'4',route:'/index/home-app'},
  {id:42,name:'轮播管理',icon:'',parentId:'4',route:'/index/banner'},
  {id:43,name:'用户反馈',icon:'',parentId:'4',route:'/index/feedback'},
  {id:45,name:'新品介绍',icon:'',parentId:'4',route:'/index/goods-new'},


  {id:7,name:'网站管理',icon:'fa  fa-home',parentId:'',route:''},
  {id:71,name:'资讯管理',icon:'',parentId:'7',route:'/index/news'},
  {id: 72, name: '关于麦祥', icon: '', parentId: '7', route: '/index/webAbout' },
  {id: 73, name: '商务合作', icon: '', parentId: '7', route: '/index/webBusiness' },

  {id:5,name:'学习中心',icon:'fa  fa-gears',parentId:'',route:''},
  {id:51,name:'学习中心',icon:'',parentId:'5',route:'/index/study'},
  {id:52,name:'学习类目',icon:'',parentId:'5',route:'/index/study-category-list'},
  {id:53,name:'操作手册',icon:'',parentId:'5',route:'/index/manual'},
  {id:54,name:'手册类目',icon:'',parentId:'5',route:'/index/manual-category-list'},


  {id:6,name:'系统管理',icon:'fa  fa-gears',parentId:'',route:''},
  {id:61,name:'人员管理',icon:'',parentId:'6',route:'/index/user'},
];
export default menu;
