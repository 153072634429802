<template>
    <!--顶部导航栏-->
    <div style="width:100%;height:70px;display: flex;justify-content: center;align-items: center">
        <div style="width: 1280px;display: flex;justify-content: space-between;align-items: center">
<!--                <div class="top-nav-item" @click="goTo('/home')">首页</div>-->
            <div class="top-nav-item" style="position: relative;left: -35px" @click="goTo('/web')">
              首页
              <p style="font-size: 14px;line-height: 16px;margin: 0">HOME</p>
              <div class="line"></div></div>
            <div class="top-nav-item" @click="goTo('/about')">
              关于麦祥
              <p style="font-size: 14px;line-height: 16px;margin: 0">ABOUT</p>
              <div class="line"></div></div>
            <div class="top-nav-item" @click="goTo('/goods')">
              产品中心
              <p style="font-size: 14px;line-height: 16px;margin: 0">PRODUCT</p>
              <div class="line"></div></div>
            <div class="top-nav-item" @click="goTo('/business')">商务合作
              <p style="font-size: 14px;line-height: 16px;margin: 0">BUSINESS </p>
              <div class="line"></div></div>
            <div class="top-nav-item" @click="goTo('/news')">新闻资讯
              <p style="font-size: 14px;line-height: 16px;margin: 0">NEWS </p>
              <div class="line"></div></div>
            <div style="display: flex;
            align-items: center;
            width: 280px;
            height: 40px;
            padding: 0 15px;
            border: solid #E0E9E8 1px;
            border-radius: 20px">
                <img src="../../assets/images/webHeader/search1.png" style="width: 20px;height: 20px;margin-right: 10px">
                <input v-model="input" @keyup.enter="searchEvent" type="text" placeholder="搜索"  style="height: 22px;width: 215px;font-size: 17px;
                                                                                font-weight: 400;
                                                                                background: none;
                                                                                outline: none;
                                                                                border: none;
                                                                                resize: none">
            </div>
        </div>
    </div>
</template>

<script>
import MenuUtils from "@/menuUtils";
export default {
  name: 'TopNavigationBar',
  data(){
    return {
      input:'',
    }
  },
  methods:{
    goTo(url){
      MenuUtils.routerQueryTo(url);
    },
    searchEvent(){
      MenuUtils.routerQueryTo("/news",{input:this.input},true);
    }
  }
}
</script>

<style scoped>
.top-nav-item{
    height: 70px;
    width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    padding-top: 8px;
  cursor: pointer;
}
.line{
    height: 5px;
    width: 60px;
    background: none;
    position: relative;
    top: 7px;
 }
.top-nav-item:hover{
    color: #173F35;
    font-weight: bold;
    transition: all 0.3s;
}
.top-nav-item:hover>.line{
    background: #173F35;
    transition: all 0.3s;
}

input::-webkit-input-placeholder{
color: #CDCDCD;
}/*兼容WebKit browsers（Chrome的内核）修改输入框默认文字颜色*/

/*div{
    border: solid 1px red;
}*/
</style>