<template>
    <div style="margin-top: 75px;height: 400px;width: 1280px">
        <el-carousel  arrow="never" height="400px">
            <el-carousel-item v-for="item in bannerList" :key="item.id">
                <div style="display: flex;background: white" >
                    <img :src="item.img.url" style="height: 400px;width: 720px">
                    <div style="padding: 40px 25px 25px 40px;cursor: pointer" @click="detailEvent(item)">
                        <div style="font-size: 23px;font-weight: bold">{{item.title}}</div>
                        <div style="font-size: 14px;color: #636364;display: flex;align-items: center;margin-top: 15px">
                            <img src="../../assets/images/home/time.png" style="width: 14px;height: 14px;margin-right: 5px">
                            <div>{{ item.updateTime }}</div>
                        </div>
                        <div style="font-size: 16px;color: #636364;margin-top: 20px">{{item.describe}}</div>
                        <div style="margin-top: 50px">
                            <img src="../../assets/images/home/detail.png">
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
    import MenuUtils from "@/menuUtils";

    export default {
        name: "NewsCarousel",
      data(){
        return {
          bannerList:[]
        }
      },
      mounted() {
        this.$Http("newsBannerList",{},"POST").then(result=>{
          this.bannerList=result.data.data;
        })
      },
      methods:{
        detailEvent(item){
          MenuUtils.routerQueryTo("/newsDetail",{id:item.id},true)
        }
      }
    }
</script>

<style scoped>
    /deep/.el-carousel__indicators--horizontal {
        position: absolute;
        left: auto;
        right: 0;
        bottom: 20px;
        text-align: right;
    }
    /deep/.el-carousel__indicator--horizontal button {
        width: 18px;
        height: 18px;
        background-color: transparent;
        background-image: url("../../assets/images/home/dot1.png");
        border-radius: 10px;
    }
    /deep/.el-carousel__indicator--horizontal.is-active button {
        width: 18px;
        height: 18px;
        background-color: transparent;
        background-image: url("../../assets/images/home/dot2.png");
        border-radius: 10px;
    }
</style>