<template>
  <div style="width: 100%" class="outerBox" id="mianscroll">
    <WebHeader></WebHeader>
    <div class="bigImg"></div>
    <div style="width: 100%;display: flex;flex-direction: column;align-items: center;">
      <div style="width: 1280px;height: 100%;padding-bottom: 90px">
        <div style="height: 80px;width: 100%;display: flex;align-items: center;font-size: 17px;">
          <div>您的位置在：</div>
          <img src="../assets/images/news/home.png" style="margin-right: 5px">
          <div class="navText">首页</div>
          <div style="margin: 0 7px">>></div>
          <div class="navText">产品中心</div>
        </div>
        <div style="height: 1px;width: 100%;background: #DCDCDD"></div>
        <div style="display: flex;justify-content: space-between;padding: 20px 0 0 0">
          <div class="leftDiv">
            <div style="height: 4px;width: 240px;background: #173F35;border-radius: 2px"></div>
            <div style="display: flex;
            align-items: center;
            width: 280px;
            height: 50px;
            padding: 0 20px;
            background:#F3F5F7;
            border-radius: 10px;
            margin-top: 15px">
              <img src="../assets/images/webHeader/search1.png" style="width: 20px;height: 20px;margin-right: 15px">
              <input @keyup.enter="searchEvent" v-model="title" type="text" placeholder="请输入搜索内容" style="height: 22px;width: 215px;font-size: 17px;
                                                                                font-weight: 400;
                                                                                background: none;
                                                                                outline: none;
                                                                                border: none;
                                                                                resize: none">
            </div>
            <div class="itemTitle" :class="{active:item.id==typeItem.id}" v-for="item,index in newsTypeList" @click="selectTypeEvent(item)">{{item.name}}</div>
            <div style="width: 280px;height: 2px;background: #DCDCDD;margin: 20px 0 15px 0"></div>
            <div style="width: 280px;font-size: 20px;margin-bottom: 10px">热点推荐</div>
            <div style="width: 280px;margin-bottom: 20px;cursor: pointer" v-for="item,index in hotList" @click="detailEvent(item)">
              <img :src="item.img.url" style="width: 280px;height: 140px;border-radius: 15px;object-fit: cover">
              <div style="font-size: 18px;margin-top: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">{{item.title}}</div>
              <div style="font-size: 14px;color: #636364;display: flex;align-items: center;margin-top: 10px">
                <img src="../assets/images/home/time.png" style="width: 14px;height: 14px;margin-right: 10px">
                <div>{{ item.updateTime }}</div>
              </div>
            </div>
            <!--            <div style="width: 280px;height: 2px;background: #DCDCDD;margin: 10px 0 15px 0"></div>-->
            <!--            <div style="width: 280px;font-size: 20px;margin-bottom: 10px">标签云</div>-->
            <!--            <div style="width: 280px;display: flex;flex-wrap: wrap">-->
            <!--              <div-->
            <!--                  style="padding: 8px 15px;font-size: 17px;background: #F3F5F7;border-radius: 5px;margin: 0 10px 10px 0">-->
            <!--                UL认证范围-->
            <!--              </div>-->
            <!--              <div-->
            <!--                  style="padding: 8px 15px;font-size: 17px;background: #F3F5F7;border-radius: 5px;margin: 0 10px 10px 0">-->
            <!--                BIS认证清单-->
            <!--              </div>-->
            <!--              <div-->
            <!--                  style="padding: 8px 15px;font-size: 17px;background: #F3F5F7;border-radius: 5px;margin: 0 10px 10px 0">-->
            <!--                CE认证检测项目-->
            <!--              </div>-->
            <!--              <div-->
            <!--                  style="padding: 8px 15px;font-size: 17px;background: #F3F5F7;border-radius: 5px;margin: 0 10px 10px 0">-->
            <!--                浙江玩具检测ce认证-->
            <!--              </div>-->
            <!--              <div-->
            <!--                  style="padding: 8px 15px;font-size: 17px;background: #F3F5F7;border-radius: 5px;margin: 0 10px 10px 0">-->
            <!--                食品接触材料标准-->
            <!--              </div>-->
            <!--              <div-->
            <!--                  style="padding: 8px 15px;font-size: 17px;background: #F3F5F7;border-radius: 5px;margin: 0 10px 10px 0">-->
            <!--                醉花阴-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div style="width: 920px;">
            <div>
              <div style="font-size: 36px;font-weight: bolder">{{typeItem.name}}</div>
              <div style="width: 145px;height: 7px;background: #17171D"></div>
              <div style="height: 1px;width: 100%;background: #DCDCDD;position: relative;top: -1px"></div>
            </div>
            <div style="display: flex;flex-wrap: wrap;width: 920px;padding-top: 20px">
              <div class="newsItem" v-for="item in pagerData.data" @click="detailEvent(item)">
                <img :src="item.attach.url" style="width: 440px;height: 280px;margin: 10px;object-fit: cover">
                <div style="padding: 0px 10px 0px 10px">
                  <div style="font-size: 20px;font-weight: bold">{{ item.number||'-' }}</div>
                  <div style="font-size: 20px;font-weight: bold">{{ item.name }}</div>
                  <div style="font-size: 20px;font-weight: bold;margin-top: 5px">￥{{item.price||0}}</div>
                  <div style="margin-top: 5px">
                    <img src="../assets/images/home/detail.png">
                  </div>
                </div>
              </div>
              <div v-if="pagerData.data==null||pagerData.data.length==0" style="padding: 100px;text-align: center;font-size: 36px;width: 100%">
                <h1>暂无数据</h1>
              </div>
            </div>
            <div style="width: 100%;display: flex;justify-content: center">
              <div style="height: 40px;display: flex;justify-content: space-between;margin-top: 30px">
                <div
                    style="cursor: pointer;width: 40px;height:40px;border: #DFDFE0 solid 1px;border-radius: 20px;display: flex;justify-content: center;align-items: center;margin-right: 5px">
                  <img src="../assets/images/news/left-arrow1.png">
                </div>
                <div
                    @click="decrease"
                    style="cursor: pointer;width: 40px;height:40px;border: #DFDFE0 solid 1px;border-radius: 20px;display: flex;justify-content: center;align-items: center;">
                  <img src="../assets/images/news/left-arrow2.png">
                </div>
                <div style="width: 17px"></div>
                <div class="page-item" @click="pageEvent(1)" v-if="pageIndex>1">1</div>
                <div class="page-item" v-if="pageIndex>4" @click="preEvent">...</div>
                <template v-for="item in [2,1]">
                  <div class="page-item" @click="pageEvent(pageIndex-item)" v-if="pageIndex-item>1">{{ pageIndex - item }}
                  </div>
                </template>
                <div class="page-item active">{{ pageIndex }}</div>
                <template v-for="item in 2">
                  <div class="page-item" @click="pageEvent(pageIndex+item)" v-if="pageIndex+item<pageTotal">
                    {{ pageIndex + item }}
                  </div>
                </template>
                <div class="page-item" v-if="pageIndex<pageTotal-4" @click="nextEvent">...</div>
                <div class="page-item" @click="pageEvent(pageTotal)" v-if="pageIndex<pageTotal">{{ pageTotal }}</div>
                <div style="width: 17px"></div>
                <div
                    @click="add"
                    style="cursor: pointer;width: 40px;height:40px;border: #DFDFE0 solid 1px;border-radius: 20px;display: flex;justify-content: center;align-items: center;">
                  <img src="../assets/images/news/right-arrow2.png">
                </div>
                <div
                    @click=""
                    style="cursor: pointer;width: 40px;height:40px;border: #DFDFE0 solid 1px;border-radius: 20px;display: flex;justify-content: center;align-items: center;margin-left: 5px">
                  <img src="../assets/images/news/right-arrow1.png">
                </div>
                <div style="width: 50px"></div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div style="height: 1px;width: 100%;background: #DCDCDD"></div>
    </div>

    <LevitationBar ref="LevitationBar"></LevitationBar>
    <WebFooter></WebFooter>
  </div>
</template>

<script>
import WebHeader from "@/views/components/WebHeader.vue";
import WebFooter from "@/views/components/WebFooter.vue";
import LevitationBar from "@/views/components/LevitationBar.vue";
import MenuUtils from "@/menuUtils";
import StringUtils from "@/utils/StringUtils";
export default {
  name: "Goods",
  components:{
    WebHeader,
    WebFooter,
    LevitationBar
  },
  data(){
    return {
      pageTotal: 15,
      pageIndex: 1,

      hotList:[],
      typeItem:{},
      newsTypeList:[],
      title: '',
      categoryId: this.$route.query.categoryId,
      pagerData: {pageNumber: 1, pageSize: 10, pageTotal: 0, pageItemCount: 0, data: []},
    }
  },
  methods:{
    detailEvent(item){
      MenuUtils.routerQueryTo("/goodsDetail",{id:item.id},true)
    },
    selectTypeEvent(typeItem){
      this.typeItem=typeItem;
      this.searchEvent();
    },
    searchEvent: function () {
      let query = {
        input: this.title,
        category: this.typeItem.id||'',
        pageIndex: this.pageIndex,
        pageSize: this.pagerData.pageSize
      };
      this.$Http('/goods-list', query, 'POST').then(result => {
        this.pagerData = result.data.data;
        this.pageIndex=result.data.data.pageNumber;
        this.pageTotal=result.data.data.pageTotal;
      });
    },
    decrease() {
      if (this.pageIndex > 1) {
        this.pageIndex--;
      }
      this.searchEvent();
    },
    add() {
      if (this.pageIndex < this.pageTotal) {
        this.pageIndex++;
      }
      this.searchEvent();
    },
    preEvent(){
      this.pageIndex=Math.floor(this.pageIndex/2)
      this.searchEvent();
    },
    nextEvent(){
      this.pageIndex=Math.ceil((this.pageTotal+this.pageIndex)/2);
      this.searchEvent();
    },
    pageEvent(index){
      this.pageIndex=index;
      this.searchEvent();
    },
  },
  mounted(){
    this.$Http("newsHotList",{},"POST").then(result=>{
      this.hotList=result.data.data;
    })
    let categoryId=this.$route.query.categoryId
    this.$Http('/categoryList', {  }, 'GET').then(result => {
      let array=[];
      for(let i=0;i<result.data.data.length;i++){
        let item=result.data.data[i];
        if(!item.disabled){
          array.push(item);
        }
      }
      this.newsTypeList = array;

      if(StringUtils.isNullOrEmpty(categoryId)){
        if(this.newsTypeList.length>0){
          this.selectTypeEvent(this.newsTypeList[0]);
        }
      }else{
        for(let i=0;i<this.newsTypeList.length;i++){
          let item=this.newsTypeList[i];
          if(item.id==categoryId){
            this.selectTypeEvent(item);
            break;
          }
        }
      }


    });
  }
}
</script>

<style scoped>
.outerBox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}
.bigImg{
  width: 100%;
  background-size: cover;
  height: 400px;
  background-repeat: no-repeat;
  background-image: url("../assets/images/news/goodsBanner.png");
}
.navText:hover{
  color: #173F35;
  text-decoration: #A7B0AD underline;
}
.itemTitle{
  height: 50px;
  width: 280px;
  border-radius: 10px;
  margin-top: 8px;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.itemTitle:hover,.itemTitle.active{
  background: #173F35;
  color: white;
}
.leftDiv{
  width: 320px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow:   0 0, 15px 0px 15px 0px #EBECED, 0px 15px 15px 0px #EBECED, -15px 0px 15px 0px #EBECED;

}
.newsItem{
  width: 460px;
  height: 450px;
  margin-bottom: 10px;
  cursor: pointer;
}
.newsItem:hover{
  box-shadow:   0 0, 10px 0px 10px 0px #EBECED, 0px 10px 10px 0px #EBECED, -10px 0px 10px 0px #EBECED;
}

.num,.page-item{
  width: 40px;
  height: 40px;
  border: #DFDFE0 solid 1px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 3px;
  cursor: pointer;
}

.num:hover,.page-item:hover,.page-item.active {
  color: white;
  background: #173F35;
  border: none;
}

input::-webkit-input-placeholder{
  color: #C3C5C7;
}/*兼容WebKit browsers（Chrome的内核）修改输入框默认文字颜色*/
/*div{
    border: red solid 1px;
}*/
</style>