<template>
  <!-- 悬浮栏-->
  <div class="levitation-bar">
<!--    <div class="levitation-bar-item">-->
<!--      <img src="../../assets/images/levitationBar/phone.png">-->
<!--    </div>-->
<!--    <div class="levitation-bar-item">-->
<!--      <img src="../../assets/images/levitationBar/QQ.png">-->
<!--    </div>-->
<!--    <div class="levitation-bar-item">-->
<!--      <img src="../../assets/images/levitationBar/wx.png">-->
<!--    </div>-->
<!--    <div class="levitation-bar-item">-->
<!--      <img src="../../assets/images/levitationBar/message.png">-->
<!--    </div>-->
<!--    <div style="height: 290px"></div>-->
    <div class="levitation-bar-item" @click="toTop">
      <img src="../../assets/images/levitationBar/to-top.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'LevitationBar',
  data() {
    return {
      scrollTop: 0, //默认距离顶部的距离
      scrollTrigger: false //默认初始值
    }
  },
  methods: {
    // 返回顶部事件
    toTop() {
      $('#mianscroll').animate({scrollTop: 0}, 800);
    }
  }
}
</script>

<style scoped>
.levitation-bar {
  position: fixed;
  right: 45px;
  top: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 999;
}

.levitation-bar-item {
  display: flex;
  margin-bottom: 10px
}
</style>