<template>
    <div style="width: 100%;height: 340px;background: white;display: flex;justify-content: center">
        <div style="width: 1280px;display: flex;flex-direction: column;justify-content: space-between">
            <div style="width: 100%;height: 302px;margin: 28px 0 0 0;display: flex;justify-content: space-between">
                <div style="width: 600px">
                    <div style="width: 455px;display: flex;justify-content: space-between">
                        <div class="title" @click="goTo('/web')">首页</div>
                        <div class="title" @click="goTo('/about')">关于麦祥</div>
                        <div class="title" @click="goTo('/goods')">产品中心</div>
                        <div class="title" @click="goTo('/business')">商务合作</div>
                        <div class="title" @click="goTo('/news')">新闻资讯</div>
                    </div>
                    <div style="margin-top: 30px">
                        <div style="font-size: 20px;font-weight: bold;color: #2E6C63">联系我们</div>
                        <div style="font-size: 16px;color: #97AFAC;">CONTACT US</div>
                    </div>
                    <div style="margin-top: 25px">
                        <div style="height: 22px;display: flex;align-items: flex-end;font-size: 14px;color: #616163">
                            <img src="../../assets/images/webFooter/phone.png">
                            <div style="width: 20px"></div>
                            <div>服务专线：15381993108</div>
                        </div>
                        <div style="height: 22px;display: flex;align-items: flex-end;font-size: 14px;color: #616163;margin-top: 17px">
                            <img src="../../assets/images/webFooter/mailbox.png">
                            <div style="width: 20px"></div>
                            <div>售前咨询邮箱：walter.han@163.com</div>
                            <div style="width: 35px"></div>
                            <div>售后咨询邮箱：walter.han@163.com</div>
                        </div>
                        <div style="height: 22px;display: flex;align-items: flex-end;font-size: 14px;color: #616163;margin-top: 17px">
                            <img src="../../assets/images/webFooter/address.png">
                            <div style="width: 20px"></div>
                            <div>地址：浙江省宁波市鄞州区富强路555号4楼</div>
                        </div>
                    </div>

                </div>
                <div style="width: 270px;margin: 5px 60px 0 0;display: flex;flex-direction: column;align-items: center">
                    <div style="display: flex">
                        <img src="../../assets/images/webHeader/phone1.png" style="width: 44px;height:44px">
                        <div style="display: flex;flex-direction: column;justify-content:space-between;margin-left: 15px;">
                            <div style="color: #415B54;font-size: 12px;">服务热线</div>
                            <div style="color: #2C6B62;font-size: 18px;"><strong>0574-89201298</strong></div>
                        </div>
                    </div>
                    <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 42px">
                        <div style="display: flex;flex-direction: column;align-items: center">
                            <div style="width: 107px;height: 107px;background: #0fa3c1">
                              <img style="width: 107px;height: 107px;" src="../../assets/images/webFooter/gzh.jpg"/>
                            </div>
                            <div style="margin-top: 15px;font-size: 14px">微信公众号</div>
                        </div>
                        <div style="display: flex;flex-direction: column;align-items: center">
                            <div style="width: 107px;height: 107px;background: #0fa3c1">
                              <img style="width: 107px;height: 107px;" src="../../assets/images/webFooter/wechat-app.jpg"/>
                            </div>
                            <div style="margin-top: 15px;font-size: 14px">微信小程序</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 100%;height: 1px;background: #DCDCDD"></div>
            <div style="width: 100%;height:37px;color: #929293;display: flex;justify-content: center;align-items: center">
                备案号：Copyright @ 2009-2018 宁波麦祥科技集团有限公司 版权所有&emsp;&emsp;浙ICP备17045120号-3
            </div>
        </div>
    </div>
</template>

<script>
    import MenuUtils from "@/menuUtils";

    export default {
        name: "WebFooter",
      methods:{
        goTo(url){
          MenuUtils.routerQueryTo(url);
        }
      }
    }
</script>

<style scoped>
    .title{
        font-size: 17px;
        border-bottom: transparent solid 1px;
    }
    .title:hover{
        color: #194137;
        border-bottom: #194137 solid 1px;
    }
</style>