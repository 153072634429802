<template>
    <div style="width:100%;height:80px;display: flex;justify-content: center;align-items: center">
        <div style="width: 1280px;display: flex;justify-content: space-between">
            <div style="display: flex;align-items: center">
                <img src="../../assets/images/webHeader/logo.png" style="width: 300px;height:40px">
            </div>
            <div style="display: flex;align-items: center">
                <img src="../../assets/images/webHeader/phone1.png" style="width: 44px;height:44px">
                <div style="display: flex;flex-direction: column;justify-content:space-between;margin-left: 10px;">
                    <div style="color: #000;font-size: 12px;">服务热线</div>
                    <div style="color: #000;font-size: 18px;"><strong>0574-89201298</strong></div>
                </div>
                <div style="width: 45px"></div>
                <img src="../../assets/images/webHeader/phone2.png" style="width: 44px;height:44px">
                <div style="display: flex;flex-direction: column;justify-content: space-between;margin-left: 10px;">
                    <div style="color: #000;font-size: 12px;">24小时服务专线</div>
                    <div style="color: #000;font-size: 20px;"><strong>15381993108</strong></div>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%;height: 1px; background: #DCDCDD"></div>
    <TopNavigationBar></TopNavigationBar>
</template>

<script>
    import TopNavigationBar from "@/views/components/TopNavigationBar.vue";
    export default {
        name: "WebHeader",
        components:{
            TopNavigationBar,
        },
    }
</script>

<style scoped>

</style>